import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { FaSearch } from 'react-icons/fa'


const ViewClub = () => {

  const [clubNumber, setClubNumber] = useState('')
  const [clubName, setClubName] = useState('')
  const [president, setPresident] = useState('')
  const [secretory, setSecretory] = useState('')
  const [treasurer, setTreasurer] = useState('')
  const [clubSponsoredBy, setClubSponsoredBy] = useState('')
  const [chartedOn, setChartedOn] = useState('')
  const [extn, setExtn] = useState('')
  const [meets, setMeets] = useState('')
  const [place, setPlace] = useState('')
  const [region, setRegion] = useState('')
  const [charter, setCharter] = useState('')
  const [zone, setZone] = useState('')
  const [totalMembers, setTotalMembers] = useState('')
  const [category, setCategory] = useState('')




  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [club, setClub] = useState([])
  const [getClubName, setGetClubName] = useState([])
  const [showPerPage, setShowPerPage] = useState(20)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const clubData = async () => {
    const res = await axios.get(`https://lions320dapi.emedha.in/clubData`)
    setClub(res.data)
  }

  useEffect(() => {
    clubData()
  }, []);

  const submitData = (e) => {
    e.preventDefault();
    axios.post('https://lions320dapi.emedha.in/clubCreate', {
      clubNumber,
      clubName,
      president,
      secretory,
      treasurer,
      clubSponsoredBy,
      chartedOn,
      extn,
      place,
      meets,
      region,
      charter,
      zone,
      totalMembers,
      category
    }).then(() => {
      alert('Data Successfully Inserted!')
      setShow(false)
      setTimeout(() => clubData(), 100)
    }).catch((err) => alert(err.res.data))
  }




  const deleteData = (id) => {
    if (window.confirm('Are you sure to delete this data...')) {
      axios.delete(`https://lions320dapi.emedha.in/deleteClub/${id}`)
      alert('Data deleted successfully!...')
      setTimeout(() => clubData(), 100)
    }
  }

  const searchHandler = (search) => {
    axios.post('https://lions320dapi.emedha.in/search/club', {
      search
    }).then((resp) => {
      setClub(resp.data)
    })
  }


  return (

    <>
      <Button variant="primary" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <br /><br /><br /><br /><br />
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitData}>
            <input type='number' className='form-control' placeholder='Club Number' required onChange={(e) => setClubNumber(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Club Name' required onChange={(e) => setClubName(e.target.value)} />

            <br />
            <input type='text' className='form-control' placeholder='President' onChange={(e) => setPresident(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Secretary' onChange={(e) => setSecretory(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Treasurer' onChange={(e) => setTreasurer(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Club Sponsored By' onChange={(e) => setClubSponsoredBy(e.target.value)} />
            <br />
            <input type='date' className='form-control' placeholder='Charted On' onChange={(e) => setChartedOn(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Charter President' onChange={(e) => setCharter(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Extn Chairperson' onChange={(e) => setExtn(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Meets On' onChange={(e) => setMeets(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Place' onChange={(e) => setPlace(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Region Chairperson' onChange={(e) => setRegion(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Zone Chairperson' onChange={(e) => setZone(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Total Members' onChange={(e) => setTotalMembers(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Category' onChange={(e) => setCategory(e.target.value)} />
            <br />
            <button type='submit' className='btn btn-danger form-control'>Submit</button>
            <br />
            <br />
            <button type='reset' className='btn btn-secondary form-control'>Reset</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <FaSearch className='searchIcon'></FaSearch><input type='search' className='ms-4 search-input-field' placeholder='Search..' onChange={(e)=>searchHandler(e.target.value)} />
      <div style={{ marginTop: '1rem' }} className='table-container'>
        <table className="styled-table table table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Club_Name</th>
              <th>Club_Number</th>
              <th>President</th>
              <th>Scretary</th>
              <th>Treasurer</th>
              <th>Club_Sponsored_By</th>
              <th>Charted_On</th>
              <th>Place</th>
              <th>Region_Chairperson</th>
              <th>Zone_Chairperson</th>
              <th>Total_Mambers</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>

          </thead>
          <tbody className='table-bordered'>
            {
              club.slice(pagination.start, pagination.end).map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.clubName}</td>
                    <td>{data.clubNumber}</td>
                    <td>{data.president}</td>
                    <td>{data.secretory}</td>
                    <td>{data.treasurer}</td>
                    <td>{data.clubSponsoredBy}</td>
                    <td>{data.chartedOn}</td>
                    <td>{data.place}</td>
                    <td>{data.regionChairperson}</td>
                    <td>{data.zoneChairperson}</td>
                    <td>{data.totalMembers}</td>
                    <td className=''>
                      <Link to={`/update-club/${data.clubName}/${data.clubNumber}`} className='btn btn-info' style={{ color: 'white' }}>update</Link>
                    </td>
                    <td className=''>
                      <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.clubId)}>delete</Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={club.length} />
      </div>
    </>
  )
}

export default ViewClub