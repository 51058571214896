import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const InputProjects = () => {

  const [clubSelectName,setClubSelectName] = useState([])
  const [clubName,setClubName]=useState('')
  const [projectName,setProjectName] = useState()
  const [projectDescription,setProjectDescription] = useState()
  const [photo,setPhoto] = useState()
  const [contact,setContact] = useState()

 

  const getClubName=async()=>{
    const res=await axios.get('https://lions320dapi.emedha.in/clubName/get')
    setClubSelectName(res.data)
  }


  const navigate = useNavigate()
  const postProject= ()=>{
  
    const formData = new FormData();
  
    formData.append('projectName',projectName)
    formData.append('projectDescription',projectDescription)
    formData.append('contact',contact)
    formData.append('photo',photo)
    formData.append('clubName',clubName)
  
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    axios.post("https://lions320dapi.emedha.in/project/post",formData,config)
    .then((response) => {
      alert(response.data);
      setTimeout(()=>navigate('/view-project'),300)
        }).catch((error) => {
          console.log(error)
      });
  }

   

  useEffect(()=>{
     getClubName()
  },[])
   

  return (
    <div className='conatner add-project-container'>
      <label>Project Name</label>
        <input type='text' className='form-control' placeholder='Project Name' onChange={(e)=> setProjectName(e.target.value)}/>
        <br></br>
        <select type='text' className='form-control' placeholder='Designation'  onChange={(e)=>setClubName(e.target.value)}>
            <option>
            -------Select ClubName----
            </option>
            {
              clubSelectName.map((data)=>{
                return(
                     
                    <option value={data.clubName}>
                      {data.clubName}
                    </option>
                    
                )
              })
            }
        </select>
      <br></br>
      <label>Description</label>
      <textarea type='text' rows={4} className='form-control' placeholder='Project Description' onChange={(e)=>setProjectDescription(e.target.value)}/>
      <br></br>
      <input type='file' placeholder='Choose File...' name='photo' className='form-control' onChange={(e)=>setPhoto(e.target.files[0])}/>
      <label>Contact</label>
      <textarea type='text' rows={3} className='form-control' placeholder='Contact me...?' onChange={(e)=>setContact(e.target.value)}/>
      <br></br>
      <button className='btn btn-danger mb-2' onClick={postProject}>Submit</button>
      <br></br>
    </div>
  )
}

export default InputProjects