import axios from 'axios'
import React from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'

const DGDiaryUpdate = () => {

    const {id} = useParams()

    const inproject ={
        startDate:'',
        endDate:'',
        program:'',
        description:''
      }
    
      const [diary,setDiary] = useState(inproject)
      const {startDate,endDate,program,description}=diary
    
      const navigate = useNavigate()
      const updateDiary = ()=>{
        axios.post('https://lions320dapi.emedha.in/diary/update',{
            startDate,
            endDate,
            program,
            description,
            id
        }).then((res)=>{
            alert(res.data)
            setTimeout(()=>navigate('/DG-diary'),200)
        })
      }
     
      const handleShow = () =>{
        axios.post('https://lions320dapi.emedha.in/diary/update/get',{
            id
        }).then((res)=>{
            setDiary({...res.data[0]})
        })    
      }
      
      useEffect(()=>{
        handleShow()
      },[])

      const onInputChange=(e)=>{
        setDiary({...diary,[e.target.name]:e.target.value})
      }

  return (
    <div className='conatner add-project-container'>
    <label>Start Date</label>
      <input type='date' value={startDate} name='startDate'  className='form-control'  onChange={onInputChange}/>
      <br></br>
      <label>End Date</label>
      <input type='date' value={endDate} name='endDate' className='form-control' onChange={onInputChange}/>
      <br></br>
      <label>Program</label>
      <input type='text' value={program} name='program' className='form-control' placeholder='program' onChange={onInputChange}/>
      <br></br>
      <label>Description</label>
      <textarea type='text' name='description'  value={description} rows={6} className='form-control' placeholder='Description' onChange={onInputChange}/>
      <br></br>
    <button className='btn btn-danger mb-2' onClick={updateDiary}>Submit</button>
    <br></br>
  </div>
  )
}

export default DGDiaryUpdate