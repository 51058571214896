import React, { useState, useEffect } from 'react'
import Pagination from '../pagination/Pagination';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// Import Material Icons



const Menu = () => {

  const [name, setName] = useState('')
  const [route, setRoute] = useState('')
  const [icon1, setIcon1] = useState('')
  const [icon2, setIcon2] = useState('')
  const [flag, setFlag] = useState('')

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Menu, setMenu] = useState([])
  const [showPerPage, setShowPerPage] = useState(20)
  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showPerPage
    }
  )

  const onChangePagination = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const MenuData = async () => {
    const res = await axios.get(`https://lions320dapi.emedha.in/sub/menu/get`, {
    })
    setMenu(res.data)
  }

  const menuPost = (e) => {
    e.preventDefault();
    axios.post("https://lions320dapi.emedha.in/menu/post", {
      name,
      route,
      icon1,
      icon2,
      flag,
    })
      .then((response) => {
        alert(response.data);
        setTimeout(() => setShow(false), 200)
        MenuData()
      }).catch((error) => {
        console.log(error)
      });

  }


  useEffect(() => {
    MenuData()
  }, []);


  const deleteData = (id) => {
    if (window.confirm('Are you sure to delete this data...')) {
      axios.post(`https://lions320dapi.emedha.in/Menu/delete`, {
        id
      })
      alert('Data deleted successfully!...')
      setTimeout(() => MenuData(), 100)
      MenuData()
    }
  }

  const search = () => {

  }




  return (


    <>
      <Button variant="warning" onClick={handleShow}>
        Add<PersonAddAltIcon></PersonAddAltIcon>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={menuPost}>
            <label>Name</label>
            <input type='text' className='form-control' placeholder='Name' onChange={(e) => setName(e.target.value)} required />
            <br />

            <select type='text' className='form-control' placeholder='Route' onChange={(e) => setRoute(e.target.value)} >
              <option value='' >
                Route
              </option>
              <option value='InternationalPageItem'>
                International Sub Menu
              </option>
              <option value='Multiple'>
                Multiple Sub Menu
              </option>
              <option value='District'>
                District Sub Menu
              </option>
            </select>
            <br />

            <select type='text' className='form-control' placeholder='Flag' onChange={(e) => setFlag(e.target.value)} >
              <option value=''>
                Flag
              </option>
              <option value='1'>
                International Sub Menu
              </option>
              <option value='2'>
                Multiple Sub Menu
              </option>
              <option value='3'>
                District Sub Menu
              </option>
            </select>
            <br />
            <input type='text' className='form-control' placeholder='Icon1 (align-left)' onChange={(e) => setIcon1(e.target.value)} />
            <br />
            <input type='text' className='form-control' placeholder='Icon2 (right)' onChange={(e) => setIcon2(e.target.value)} />
            <br />


            <button type='submit' className='btn btn-danger form-control'>Submit</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ marginTop: '1rem' }} className='table-container'>
        <table className="styled-table table table-bordered">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Route</th>
              <th>Icon1</th>
              <th>Icon2</th>
              <th>Delete</th>
            </tr>

          </thead>
          <tbody className='table-bordered'>
            {

              Menu.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.route}</td>
                    <td>{data.icon1}</td>
                    <td>{data.icon2}</td>
                    <td className=''>
                      <Link to='#' className='btn btn-danger' onClick={() => deleteData(data.sno)}>delete</Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Menu.length} />
      </div>
    </>
  )
}

export default Menu