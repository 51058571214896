
import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const InputEvent = () => {

  const [name,setName] = useState()
  const [eventdate,setEventdate] = useState()
  const [venue,setVenue] = useState()

  const navigate = useNavigate()
  const eventPost=()=>{
    axios.post('https://lions320dapi.emedha.in/event/post',{
      name,
      eventdate,
      venue
    }).then((res)=>{
      alert(res.data)
      setTimeout(()=>navigate('/view-events'),200)
    })
  }

  return (
    <div className='conatner add-project-container'>
      <label>Event Name</label>
        <input type='text' className='form-control' placeholder='Event Name' onChange={(e)=> setName(e.target.value)}/>
        <br></br>
        <label>Event Date</label>
        <input type='date' className='form-control' placeholder='Event Date' onChange={(e)=> setEventdate(e.target.value)}/>
        <br></br>
        <label>Venue</label>
        <textarea type='text' rows={6} className='form-control' placeholder='Venue' onChange={(e)=>setVenue(e.target.value)}/>
        <br></br>
        <button className='btn btn-danger mb-2' onClick={eventPost}>Submit</button>
        <br></br>
    </div>
  )
}

export default InputEvent