// import React, { useEffect, useState } from 'react';
// import './Gallery.css'; // Import CSS file for styling

// const Gallery = () => {
//   const [images, setImages] = useState([]);


//     const fetchImages = async () => {
//       try {
//         const response = await fetch('https://lions320dapi.emedha.in/gallery/not-approved');
//         const data = await response.json();
//         setImages(data);
//       } catch (error) {
//         console.error('Error fetching images:', error);
//       }
//     };


//     useEffect(() => {

//     fetchImages();
//   }, []);

//   const handleApprove = async (id) => {
//     try {
//         const response = await fetch(`https://lions320dapi.emedha.in/approve/${id}`, { method: 'POST' });
//         if (response.ok) {
//             // Optionally, refresh the gallery or update the state
//             console.log(`Image ${id} approved.`);
//             alert(`Image Successfully Approved.`);
//             fetchImages();
//         } else {
//             console.error('Error approving image:', await response.json());
//         }
//     } catch (error) {
//         console.error('Error approving image:', error);
//     }
// };

// const handleDelete = async (id) => {
//     try {
//         const response = await fetch(`https://lions320dapi.emedha.in/delete/${id}`, { method: 'DELETE' });
//         if (response.ok) {
//             // Optionally, refresh the gallery or update the state
//             console.log(`Image ${id} deleted.`);
//             alert(`Image successfully deleted.`);
//             fetchImages();
//         } else {
//             console.error('Error deleting image:', await response.json());
//         }
//     } catch (error) {
//         console.error('Error deleting image:', error);
//     }
// };


//   return (
//     <div className="gallery-container">
//       <h1 style={{color: "rgb(245, 170, 30)" }}>Image Gallery</h1>
//       <div className="gallery-grid">
//         {images.map((image) => (
//           <div key={image.id} className="gallery-item">
//             <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
//             <div className="button-container">
//               <button className="approve-button" onClick={() => handleApprove(image.id)}>
//                 Approve
//               </button>
//               <button className="delete-button" onClick={() => handleDelete(image.id)}>
//                 Delete
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Gallery;



import React, { useEffect, useState } from 'react';
import './Gallery.css'; // Import CSS file for styling

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [approvedImages, setApprovedImages] = useState([]); // To hold approved images

  // Fetch non-approved images
  const fetchImages = async () => {
    try {
      const response = await fetch('https://lions320dapi.emedha.in/gallery/not-approved');
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Error fetching non-approved images:', error);
    }
  };

  // Fetch approved images
  const fetchApprovedImages = async () => {
    try {
      const response = await fetch('https://lions320dapi.emedha.in/gallery/approved');
      const data = await response.json();
      setApprovedImages(data);
    } catch (error) {
      console.error('Error fetching approved images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchApprovedImages();
  }, []);

  // Approve an image
  const handleApprove = async (id) => {
    try {
      const response = await fetch(`https://lions320dapi.emedha.in/approve/${id}`, { method: 'POST' });
      if (response.ok) {
        console.log(`Image ${id} approved.`);
        alert(`Image Successfully Approved.`);
        fetchImages(); // Refresh non-approved images
        fetchApprovedImages(); // Refresh approved images
      } else {
        console.error('Error approving image:', await response.json());
      }
    } catch (error) {
      console.error('Error approving image:', error);
    }
  };

  // Delete an image
  const handleDelete = async (id, status) => {
    try {
      const url = status === 'Approved' 
        ? `https://lions320dapi.emedha.in/delete/${id}` 
        : `https://lions320dapi.emedha.in/delete/${id}`;

      const response = await fetch(url, { method: 'DELETE' });
      if (response.ok) {
        console.log(`Image ${id} deleted.`);
        alert(`Image successfully deleted.`);
        fetchImages(); // Refresh non-approved images
        fetchApprovedImages(); // Refresh approved images
      } else {
        console.error('Error deleting image:', await response.json());
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
//     <div className="gallery-container">
//       <h1 style={{ color: "rgb(245, 170, 30)" }}>Image Gallery</h1>
      
//       {/* Non-Approved Images Section */}
//       <h2>Non-Approved Images</h2>
//       <div className="gallery-grid">
//   {images.length === 0 ? (
//     <p style={{textAlign:'center'}}>No non-approved images available.</p> // This message will display when there are no non-approved images
//   ) : (
//     images.map((image) => (
//       <div key={image.id} className="gallery-item">
//         <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
//         <div className="button-container">
//           <button className="approve-button" onClick={() => handleApprove(image.id)}>
//             Approve
//           </button>
//           <button className="delete-button" onClick={() => handleDelete(image.id, 'Not Approved')}>
//             Delete
//           </button>
//         </div>
//       </div>
//     ))
//   )}
// </div>


//       {/* Approved Images Section */}
//       <h2>Approved Images</h2>
//       <div className="gallery-grid">
//         {approvedImages.map((image) => (
//           <div key={image.id} className="gallery-item">
//             <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
//             <div className="button-container">
//               <button className="delete-button" onClick={() => handleDelete(image.id, 'Approved')}>
//                 Delete
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
<div className="gallery-container">
{/* <h1 style={{ color: "rgb(245, 170, 30)" }}>Image Gallery</h1> */}
  {/* Non-Approved Images Section */}
  <h2 style={{color:'#ff8c00',marginTop:'0px'}}>Non-Approved Images</h2>
  <div className="gallery-grid">
    {images.length === 0 ? (
      <p className="no-images-message">No non-approved images available.</p> // Centered text when no images
    ) : (
      images.map((image) => (
        <div key={image.id} className="gallery-item">
          <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
          <div className="button-container">
            <button className="approve-button" onClick={() => handleApprove(image.id)}>
              Approve
            </button>
            <button className="delete-button" onClick={() => handleDelete(image.id, 'Not Approved')}>
              Delete
            </button>
          </div>
        </div>
      ))
    )}
  </div>

  {/* Margin between sections */}
  <div style={{ marginTop: '30px' }}></div>

  {/* Approved Images Section */}
  <h2 style={{color:'#ff8c00'}}>Approved Images</h2>
  <div className="gallery-grid">
    {approvedImages.map((image) => (
      <div key={image.id} className="gallery-item">
        <img src={image.image} alt={`Image ${image.id}`} className="gallery-image" />
        <div className="button-container">
          <button className="delete-button" onClick={() => handleDelete(image.id, 'Approved')}>
            Delete
          </button>
        </div>
      </div>
    ))}
  </div>
</div>

  );
};

export default Gallery;
