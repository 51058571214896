import React, { useState,useEffect } from 'react'
import axios from 'axios';
import logo1 from '../img/320d.jpg'
import { useNavigate } from 'react-router-dom';
 
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
   
  }
from 'mdb-react-ui-kit';



const AdminLogin = ({condition}) => {

 
const navigate = new useNavigate()
const [email,setEmail]=useState()
const [password,setPassword]=useState()

const [errMsg,setErrMsg]=useState()
const [message,setMessage]=useState()


const adminLogin= async ()=>{
    await axios.post('https://lions320dapi.emedha.in/admin',{
      email,
      password
    }).then((resp)=>{
      if(resp.data==='Login Succcessful !..'){
        alert(resp.data)
        sessionStorage.setItem('email',  email)
        condition(sessionStorage.getItem('email'))
      }else{
        alert(resp.data)
      }
    })
}

 
 
  return (
    <div>
      <br/><br/><br/><br/>
        <MDBContainer fluid>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>
                <MDBCard className='bg-warning text-white my-2 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
                <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                    <h2 className="fw-bold mb-4 text-uppercase"><img src={logo1} height='100px' width='100px' className='logo1' /></h2>
                    <h2 className=" mb-2 text-uppercase">Admin Login</h2>
                    <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white'  placeholder='UserName/Email' id='formControlLg' type='text' size="lg" onChange={(e)=>setEmail(e.target.value)}/>
                    <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white'   placeholder='Password' id='formControlLg' type='password' size="lg" onChange={(e)=>setPassword(e.target.value)}/>
                    <button className='btn btn-danger mx-5 px-5'  onClick={adminLogin}>Login</button>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            </MDBRow>

        </MDBContainer>
    </div>
  )
}

export default AdminLogin