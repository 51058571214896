import axios from 'axios'
import React from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'

const EventUpdate = () => {

    const {id} = useParams()

    const inproject ={
        name:'',
        eventdate:'',
        venue:'',
      }
    
      const [event,setEvent] = useState(inproject)
      const {name,eventdate,venue}=event
    
      const navigate = useNavigate()
      const eventUpdate = ()=>{
        axios.post('https://lions320dapi.emedha.in/event/update',{
            name,
            eventdate,
            venue,
            id
        }).then((res)=>{
            alert(res.data)
            setTimeout(()=>navigate('/view-events'),200)
        })
      }
     
      const handleShow = () =>{
        axios.post('https://lions320capi.emedha.in/event/update/get',{
            id
        }).then((res)=>{
            setEvent({...res.data[0]})
        })    
      }
      
      useEffect(()=>{
        handleShow()
      },[])

      const onInputChange=(e)=>{
        setEvent({...event,[e.target.name]:e.target.value})
      }

  return (
    <div className='conatner add-project-container'>
    <label>Start Date</label>
      <input type='text' value={name} name='name' placeholder='Event Name'   className='form-control'  onChange={onInputChange}/>
      <br></br>
      <label>End Date</label>
      <input type='text' value={eventdate} name='eventdate' placeholder='Event date' className='form-control' onChange={onInputChange}/>
      <br></br>
      <label>Program</label>
      <input type='text' value={venue} name='venue' className='form-control' placeholder='Venue' onChange={onInputChange}/>
      <br></br>
    <button className='btn btn-danger mb-2' onClick={eventUpdate}>Submit</button>
    <br></br>
  </div>
  )
}

export default EventUpdate