import axios from 'axios'
import React,{useState,useEffect} from 'react'
import { useParams,useNavigate } from 'react-router-dom'

const ProjectUpdate = () => {

  const {id}=useParams()
  const [photo,setPhoto] = useState()

  const[showPerPage]=useState(5)
  const [pagination,setPagination]=useState(
    {
      start:0,
      end:showPerPage
    }
  )
  
 
  const inproject ={
    projectName:'',
    projectDesc:'',
    contact:'',
    clubName:''
  }

  const [projectUpdate,setProjectUpdate] = useState(inproject)
  const {projectName,projectDesc,contact,clubName}=projectUpdate

  
 
  
 
  const handleShow = () =>{
    axios.post('https://lions320dapi.emedha.in/project/update/get',{
        id
    }).then((res)=>{
        setProjectUpdate({...res.data[0]})
    })    
  }
  
  useEffect(()=>{
    handleShow()
  },[])


  const navigate = useNavigate()
  const projectUpdateData=(e)=>{

    e.preventDefault()

    const formData = new FormData();

    formData.append('projectName',projectName)
    formData.append('projectDescription',projectDesc)
    formData.append('contact',contact)
    formData.append('photo',photo)
    formData.append('clubName',clubName)
    formData.append('id',id)

  
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
  
    axios.post("https://lions320dapi.emedha.in/project/update/post",formData,config)
    .then((response) => {
      alert(response.data);
      setTimeout(()=>navigate('/view-project'),300)
        }).catch((error) => {
      });
  
  }
  

  const onInputChange=(e)=>{
     setProjectUpdate({...projectUpdate,[e.target.name]:e.target.value})
  }


  return (
    <div className='conatner add-project-container'>
    <label>Project Name</label>
      <input type='text' name='projectName' value={projectName} className='form-control' placeholder='Project Name' onChange={ onInputChange}/>
      <br></br>
      <label>Club Name</label>
      <input type='text' name='clubName' value={clubName} className='form-control' placeholder='club name' onChange={ onInputChange}/>
      <br></br>
    <br></br>
    <label>Description</label>
    <textarea type='text' name='projectDesc' value={projectDesc} rows={4} className='form-control' placeholder='Project Description' onChange={ onInputChange}/>
    <br></br>
    <input type='file' placeholder='Choose File...' name='photo' className='form-control' onChange={(e)=>setPhoto(e.target.files[0])}/> 
    <br/>
    <label>Contact</label>
    <textarea type='text' name='contact' value={contact} rows={3} className='form-control' placeholder='Contact me...?' onChange={ onInputChange}/>   
    <br></br>
    <button className='btn btn-danger mb-2' onClick={projectUpdateData}>Submit</button>
    <br></br>
  </div>
  )
}

export default ProjectUpdate